import { get_env } from '../config/functions/app_functions';
import { UserRoleEnum } from '../enums/user';

export const UserRolePlural: Record<UserRoleEnum, string> = {
  [UserRoleEnum.founder]: 'Founders',
  [UserRoleEnum.incubator]: 'Incubators',
  [UserRoleEnum['business-owner']]: 'Business Owners',
  [UserRoleEnum.accelerator]: 'Accelerators',
  [UserRoleEnum['private-investor']]: 'Private Investors',
  [UserRoleEnum['venture-studio']]: 'Venture Studios',
  [UserRoleEnum.freelancer]: 'Freelancers',
  [UserRoleEnum['coworking-space']]: 'Coworking Spaces',
  [UserRoleEnum.consultant]: 'Consultants',
  [UserRoleEnum.employee]: 'Employees',
  [UserRoleEnum.agency]: 'Agencies',
  [UserRoleEnum.fund]: 'Funds',
};

// export const UserRoleId: Record<UserRoleEnum, string> = {
//   [UserRoleEnum.founder]: '876a103f-6064-4256-ff17-08dbca9b1610',
//   [UserRoleEnum['business-owner']]: 'd4248356-58d6-4673-ff19-08dbca9b1610',
//   [UserRoleEnum['private-investor']]: 'c012d41a-0bc8-45ae-ff1a-08dbca9b1610',
//   [UserRoleEnum.fund]: 'c800d50f-bdd3-4d34-ff18-08dbca9b1610',
//   [UserRoleEnum.incubator]: '12418f22-106e-479a-ff1e-08dbca9b1610',
//   [UserRoleEnum.accelerator]: '54355e4d-c51e-456a-ff1f-08dbca9b1610',
//   [UserRoleEnum['venture-studio']]: '3ce24498-6370-4608-ff20-08dbca9b1610',
//   [UserRoleEnum['coworking-space']]: '2cd50074-4d56-4c3a-ff21-08dbca9b1610',
//   [UserRoleEnum.freelancer]: '4b06405f-215b-4e4d-ff1b-08dbca9b1610',
//   [UserRoleEnum.consultant]: 'acfcf800-7ae8-4d86-ff1c-08dbca9b1610',
//   [UserRoleEnum.agency]: 'd3621009-d734-49f1-ff1d-08dbca9b1610',
//   [UserRoleEnum.employee]: 'f7369e43-a622-4c5f-ff22-08dbca9b1610',
// };

const stageUserRoleId: Record<UserRoleEnum, string> = {
  [UserRoleEnum.founder]: '876a103f-6064-4256-ff17-08dbca9b1610',
  [UserRoleEnum['business-owner']]: 'd4248356-58d6-4673-ff19-08dbca9b1610',
  [UserRoleEnum['private-investor']]: 'c012d41a-0bc8-45ae-ff1a-08dbca9b1610',
  [UserRoleEnum.fund]: 'c800d50f-bdd3-4d34-ff18-08dbca9b1610',
  [UserRoleEnum.incubator]: '12418f22-106e-479a-ff1e-08dbca9b1610',
  [UserRoleEnum.accelerator]: '54355e4d-c51e-456a-ff1f-08dbca9b1610',
  [UserRoleEnum['venture-studio']]: '3ce24498-6370-4608-ff20-08dbca9b1610',
  [UserRoleEnum['coworking-space']]: '2cd50074-4d56-4c3a-ff21-08dbca9b1610',
  [UserRoleEnum.freelancer]: '4b06405f-215b-4e4d-ff1b-08dbca9b1610',
  [UserRoleEnum.consultant]: 'acfcf800-7ae8-4d86-ff1c-08dbca9b1610',
  [UserRoleEnum.agency]: 'd3621009-d734-49f1-ff1d-08dbca9b1610',
  [UserRoleEnum.employee]: 'f7369e43-a622-4c5f-ff22-08dbca9b1610',
};

const productionUserRoleId: Record<UserRoleEnum, string> = {
  [UserRoleEnum.founder]: '845be079-cbc6-4ab8-98bc-7b597a9d51dd',
  [UserRoleEnum['business-owner']]: '4f8d6514-2941-4548-b0e2-d99eb721d5ec',
  [UserRoleEnum['private-investor']]: '7000dffd-8ab2-49d8-95bc-d10745c7eb12',
  [UserRoleEnum.fund]: '848834e6-2fd9-427c-92ef-833f4e15fff9',
  [UserRoleEnum.incubator]: 'b2beb254-5ba1-48ae-a61a-3aff05a7d370',
  [UserRoleEnum.accelerator]: 'b6f62dac-1fb1-46a8-96a4-fb04ea4f708a',
  [UserRoleEnum['venture-studio']]: '0c8cd5e1-f02f-48a2-b31b-33cf0dc9b440',
  [UserRoleEnum['coworking-space']]: '814ac921-9d29-484d-a217-36079ed35708',
  [UserRoleEnum.freelancer]: 'ed2a3ed4-9560-4f12-9f9f-54042b461c6a',
  [UserRoleEnum.consultant]: 'dd7f7ffd-40f7-4786-8fa1-0eafee6fa151',
  [UserRoleEnum.agency]: 'c80f633c-779c-4ecb-a00f-e7cf8b055eb1',
  [UserRoleEnum.employee]: 'a2243bd6-904a-4090-813e-a2e20fb93a1c',
};

export const UserRoleId = get_env() === 'production' ? productionUserRoleId : stageUserRoleId;

export const DEFAULT_USER_ROLE = Object.keys(UserRoleEnum)[0] as UserRoleEnum;
