import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import { ReactComponent as DudeIll } from '../../../../../../asset/images/not_found/dude_with_telescope_and_waves.svg';
import styles from './CompleteProfile.module.scss';
import classNames from 'classnames';
import DudeSection from '../../../../components/sections/DudeSection/DudeSection';
import MainButton from '../../../../../../components/buttons/MainButton/MainButton';

interface CompleteProfileProps {}

const CompleteProfile: FunctionComponent<CompleteProfileProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  return (
    <>
      <HeaderSection
        title={`Hi, ${userFirstName}! 👋`}
        text="Complete your profile to start receiving dinner invitations."
      />

      <DudeSection>
        <p>
          We can’t wait to connect you with like-minded founders and investors. To get matched with the right group, we
          need a little more information from you. <b>Complete your profile and preferences</b>, and you'll be on your
          way to your first dinner.
        </p>

        <MainButton className={styles['button']}>Complete Profile Now</MainButton>
      </DudeSection>
    </>
  );
};

export default CompleteProfile;
