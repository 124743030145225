import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import { ReactComponent as DudeIll } from '../../../../../../asset/images/not_found/dude_with_telescope_and_waves.svg';
import styles from './CompleteProfile.module.scss';
import classNames from 'classnames';
import DudeSection from '../../../../components/sections/DudeSection/DudeSection';

interface EmptyProps {}

const Empty: FunctionComponent<EmptyProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  return (
    <>
      <HeaderSection title={`Hi, ${userFirstName}! 👋`} text="There’s no upcoming dinner right now." />

      <DudeSection>
        Thanks for being part of Constellation Dinners! While there’s no upcoming dinner at the moment,{' '}
        <b>we’re working on matching you with the right group</b>. You’ll receive an invitation as soon as the next
        event is ready.
      </DudeSection>
    </>
  );
};

export default Empty;
