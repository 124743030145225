import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import { ReactComponent as DudeIll } from '../../../../../../asset/images/not_found/dude_with_telescope_and_waves.svg';
import styles from './AfterDinner.module.scss';
import classNames from 'classnames';
import DudeSection from '../../../../components/sections/DudeSection/DudeSection';
import Input from '../../../../../../components/inputs/Input/Input';
import MainButton from '../../../../../../components/buttons/MainButton/MainButton';

interface AfterDinnerProps {}

const AfterDinner: FunctionComponent<AfterDinnerProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  return (
    <>
      <HeaderSection
        title={`Hey, ${userFirstName}, how was your dinner? 🤔`}
        text="We’d love to hear about your experience to continue improving our dinners!"
      />

      <BaseSection>
        <div className={classNames(styles['block'], 'tile tile--padding-32px')}>
          <div className={styles['top-part']}>
            <p className={classNames(styles['text-1'], 'text text--body-1')}>
              Thank you for attending last night’s dinner! We hope it was a memorable experience. To help us continue
              creating meaningful and valuable dinners, we’d appreciate your feedback on a few aspects of the event.
            </p>
          </div>

          <div className={styles['body-part']}>
            <form>
              <div className={classNames(styles['form__part__input-layout'], 'input-layout')}>
                <div className="input-layout__row input-layout__row--medium-margin">
                  <div className="input-layout__row__col">
                    <Input label="How well were you matched with other attendees?" size="large" />
                  </div>
                </div>

                <div className="input-layout__row input-layout__row--medium-margin">
                  <div className="input-layout__row__col">
                    <Input label="How was the overall dining experience?" size="large" />
                  </div>
                </div>

                <div className="input-layout__row input-layout__row--medium-margin">
                  <div className="input-layout__row__col">
                    <Input label="Any other feedback or suggestions?" size="large" />
                  </div>
                </div>

                <div className="input-layout__row input-layout__row--medium-margin">
                  <div className="input-layout__row__col">
                    <MainButton>Submit feedback</MainButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </BaseSection>
    </>
  );
};

export default AfterDinner;
