import { FunctionComponent } from 'react';
import styles from './TablematesBlock.module.scss';
import { ReactComponent as SlackIcon } from '../../../../../asset/images/icons/slack_3.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../../asset/images/icons/external_link.svg';
import { ReactComponent as LinkedInIcon } from '../../../../../asset/images/icons/in.svg';
import classNames from 'classnames';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { NavLink } from 'react-router-dom';

interface TablematesBlockProps {
  className?: string;
}

const testTableData = [
  {
    avatar: null,
    name: 'Chris Zboncak',
    position: 'Founder/CEO',
    companyName: 'Kovacek',
    companyUrl: '#',
    linkedInUrl: '#',
    industry: 'Heathcare',
    businessType: 'SaaS',
  },
  {
    avatar: null,
    name: 'Joseph Walter',
    position: 'CFO',
    companyName: 'Abstergo Ltd.',
    companyUrl: '#',
    linkedInUrl: '#',
    industry: 'Finance',
    businessType: 'Private Ltd',
  },
  {
    avatar: null,
    name: 'Jenny Armstrong',
    position: 'CEO',
    companyName: 'Acme Co.',
    companyUrl: '#',
    linkedInUrl: '#',
    industry: 'Legal',
    businessType: 'LLP',
  },
  {
    avatar: null,
    name: 'Chris Zboncak',
    position: 'Founder/CEO',
    companyName: 'Kovacek',
    companyUrl: '#',
    linkedInUrl: '#',
    industry: 'Heathcare',
    businessType: 'SaaS',
  },
  {
    avatar: null,
    name: 'Joseph Walter',
    position: 'CFO',
    companyName: 'Abstergo Ltd.',
    companyUrl: '#',
    linkedInUrl: '#',
    industry: 'Finance',
    businessType: 'Private Ltd',
  },
];

const TablematesBlock: FunctionComponent<TablematesBlockProps> = ({ className }) => {
  return (
    <div className={classNames(styles['block'], 'tile tile--padding-32px', className)}>
      <div className={styles['top-part']}>
        <div className={styles['group-1']}>
          <div className={styles['group-1__left']}>
            <p className={classNames(styles['text-1'], 'text text--caption text--bold')}>Meet your tablemates</p>
          </div>
          <div className={styles['group-1__right']}>
            <MainButton
              sizeType="medium"
              visualType="white"
              iconLeft={<SlackIcon style={{ maxWidth: '16px' }} />}
              iconRight={<ExternalLinkIcon style={{ fill: 'var(--color-main)', maxWidth: '13px' }} />}
            >
              Slack channel
            </MainButton>
          </div>
        </div>
      </div>

      <div className={styles['body-part']}>
        <div className={styles['table']}>
          {testTableData.map((item, i) => (
            <div className={styles['table__row']} key={i}>
              <div className={styles['table__col']}>
                <div className={styles['table__content-1']}>
                  <div className={styles['table__content-1__image']}>
                    <img
                      className={styles['table__content-1__image__obj']}
                      src={require('../../../../../asset/images/avatar.png')}
                      alt="avatar"
                    />
                  </div>

                  <div className={styles['table__content-1__info']}>
                    <div className={styles['table__content-1__info__name']}>
                      <p
                        className={classNames(
                          styles['table__content-1__info__name__text'],
                          'text text--body-1 text--bold'
                        )}
                      >
                        {item.name}
                      </p>

                      <span className={styles['table__content-1__info__name__icon']}>
                        <NavLink to={item.linkedInUrl} target="_blank">
                          <LinkedInIcon style={{ fill: 'var(--color-main)', maxWidth: '13px', fillOpacity: '1' }} />
                        </NavLink>
                      </span>
                    </div>

                    <div className={styles['table__content-1__info__add-data']}>
                      <p
                        className={classNames(styles['table__content-1__info__add-data__element'], 'text text--body-2')}
                      >
                        {item.position}
                      </p>

                      <NavigationLink
                        path={item.companyUrl}
                        target="_blank"
                        className={styles['table__content-1__info__add-data__element']}
                      >
                        {item.companyName}
                      </NavigationLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles['table__col']}>
                <div className={styles['table__content-2']}>
                  <p className={classNames(styles['table__content-2__key'], 'text text--body-1 text--bold')}>
                    Industry
                  </p>

                  <p className={classNames(styles['table__content-2__value'], 'text text--body-2')}>{item.industry}</p>
                </div>
              </div>

              <div className={styles['table__col']}>
                <div className={styles['table__content-2']}>
                  <p className={classNames(styles['table__content-2__key'], 'text text--body-1 text--bold')}>
                    Business Type
                  </p>

                  <p className={classNames(styles['table__content-2__value'], 'text text--body-2')}>
                    {item.businessType}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TablematesBlock;
