import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import SectionDivider from '../../../../../../components/sections/SectionDivider/SectionDivider';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import EventCard from '../../../../components/blocks/EventCard/EventCard';
import MainButton from '../../../../../../components/buttons/MainButton/MainButton';
import NotificationCard from '../../../../components/blocks/NotificationCard/NotificationCard';
import styles from './RSVP.module.scss';
import MetaTags from '../../../../../../components/seo/MetaTags/MetaTags';

interface RSVPProps {}

const RSVP: FunctionComponent<RSVPProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  return (
    <>
      <HeaderSection title={`Hi, ${userFirstName}! 👋`} text="Ready to join your next dinner?" />

      <BaseSection>
        <EventCard
          title={'Wednesday, Oct 2 2024'}
          text={
            <>
              Get ready to kick off your summer with a bang at our fabulous Tech Innovators Networking Dinner Hosted by
              the ever-charming Saurav, this is your golden ticket to an hour of delicious food, great company, and
              unforgettable memories. Join us on July 5, 2024, from 10:30 AM to 11:30 AM in the heart of Austin, TX.
              Whether you're a foodie, a social butterfly, or just looking for a good time, this event is the perfect
              way to spice up your day. <br />
              <br />
              So mark your calendars, bring your appetite, and let's make this a morning to remember. Can't wait to see
              you there!
            </>
          }
          date="Friday, Jul 5 2024"
          time="10:30 am - 11:30am GMT-5"
          group_1_right={<MainButton>RSVP to this dinner</MainButton>}
        />

        <NotificationCard
          className={styles['notification-card']}
          content={
            <>
              Information about the restaurant and participants will be <b>available 48 hours before the dinner.</b>
            </>
          }
        />
      </BaseSection>
    </>
  );
};

export default RSVP;
