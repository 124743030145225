// DevTools.tsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './DevToolStateToggler.module.scss';

interface DevToolsProps {
  title?: string;
  states: { label: string; value: string }[];
  initialState?: string;
  onChange: (value: string) => void;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const DevTools: React.FC<DevToolsProps> = ({
  title = 'DevTools',
  states,
  initialState,
  onChange,
  position = 'bottom-right',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(initialState || states[0]?.value || '');
  const [isDragging, setIsDragging] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [isPositioned, setIsPositioned] = useState(false);
  const devToolsRef = useRef<HTMLDivElement>(null);

  // Initialize position based on the viewport
  useEffect(() => {
    if (!isPositioned && devToolsRef.current) {
      const rect = devToolsRef.current.getBoundingClientRect();

      let x = 0,
        y = 0;

      if (position.includes('right')) {
        x = window.innerWidth - rect.width - 10;
      } else {
        x = 10;
      }

      if (position.includes('bottom')) {
        y = window.innerHeight - rect.height - 10;
      } else {
        y = 10;
      }

      setCoords({ x, y });
      setIsPositioned(true);
    }
  }, [position, isPositioned]);

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedState(value);
    onChange(value);
  };

  const togglePanel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (devToolsRef.current) {
      e.preventDefault();
      e.stopPropagation();

      const rect = devToolsRef.current.getBoundingClientRect();

      // Calculate offset from where user clicked to the corner of the element
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      const onMouseMove = (moveEvent: MouseEvent) => {
        moveEvent.preventDefault();
        setCoords({
          x: moveEvent.clientX - offsetX,
          y: moveEvent.clientY - offsetY,
        });
      };

      const onMouseUp = (upEvent: MouseEvent) => {
        upEvent.preventDefault();
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        setIsDragging(false);
      };

      setIsDragging(true);
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };

  return (
    <div
      ref={devToolsRef}
      className={`${styles.devTools} ${isDragging ? styles.dragging : ''}`}
      style={{
        position: 'fixed',
        left: `${coords.x}px`,
        top: `${coords.y}px`,
      }}
    >
      <div className={styles.header} onMouseDown={handleMouseDown}>
        <div className={styles.title}>{title}</div>
        <button type="button" className={styles.toggle} onClick={togglePanel}>
          {isOpen ? '−' : '+'}
        </button>
      </div>
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.radioGroup}>
            {states.map((state) => (
              <label key={state.value} className={styles.radioLabel}>
                <input
                  type="radio"
                  name="devtool-state"
                  value={state.value}
                  checked={selectedState === state.value}
                  onChange={handleStateChange}
                  className={styles.radioInput}
                />
                {state.label}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DevTools;
