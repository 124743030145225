import classNames from 'classnames';
import { FunctionComponent } from 'react';
import styles from './AppTemplate.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import MainFooter from '../../../../../components/footers/MainFooter/MainFooter';
import { getCurrentUserRole } from '../../../../../utils/role';
import AppHeader from '../../../components/headers/AppHeader/AppHeader';
import FooterSection from '../../../components/sections/FooterSection/FooterSection';
import SectionDivider from '../../../../../components/sections/SectionDivider/SectionDivider';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import RoutesEnum from '../../../../../enums/routes';
import DevToolLinks from '../../../../../components/devTools/DevToolLinks/DevToolLinks';
import { RoutesGrouped } from '../../../../../data/routes';

interface MainTemplateProps extends React.PropsWithChildren {
  hideFooterSection?: boolean;
}

const AppTemplate: FunctionComponent<MainTemplateProps> = ({ children, hideFooterSection = false }) => {
  const navigate = useNavigate();
  const userRole = getCurrentUserRole();

  return (
    <div className={classNames(styles['main-template'], 'template main-template')}>
      <AppHeader />

      <main style={{ overflow: 'hidden' }}>
        <Outlet />

        <SectionDivider color="blue" />

        <FooterSection
          title="Supernova Dinners"
          subTitle="A collision of value"
          text="Get a curated list of 20-30 accredited investors for your intimate living room dinner, with personalized coaching, investor approvals, and follow-up support to ensure your dinner fosters meaningful connections and drives interest in your raise."
          buttonGroup={[
            <MainButton
              onClick={() => navigate(`/${userRole}/${RoutesEnum.CONNECT}/${RoutesEnum.FUNDRAISING_DINNERS}`)}
            >
              Learn more
            </MainButton>,
          ]}
        />
      </main>

      <MainFooter userRole={userRole} />

      <DevToolLinks
        title="Sitemap"
        position="bottom-left"
        links={[
          {
            label: 'Dinning Preferences',
            url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.DINING_PREFERENCES}`,
          },
          {
            label: 'Code of Conduct',
            url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.CODE_OF_CONDUCT}`,
          },
          {
            label: 'Upcoming Event',
            url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.UPCOMING_EVENT}`,
          },
          {
            label: 'Past Events',
            url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.PAST_EVENTS}`,
          },
          {
            label: 'Past Events (detailed)',
            url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.PAST_EVENTS}/past-event-test`,
          },
          {
            label: 'Preferences',
            url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.PREFERENCES}`,
          },
        ]}
      />
    </div>
  );
};

export default AppTemplate;
