import { FunctionComponent } from 'react';
import HeaderSection from '../../components/sections/HeaderSection/HeaderSection';
import BaseSection from '../../components/sections/BaseSection/BaseSection';
import EventCard from '../../components/blocks/EventCard/EventCard';
import TablematesBlock from '../../components/blocks/TablematesBlock/TablematesBlock';
import styles from './PastEventsDetailView.module.scss';
import RestaurantDetailsBlock from '../../components/blocks/RestaurantDetailsBlock/RestaurantDetailsBlock';
import MapBlock from '../../components/blocks/MapBlock/MapBlock';
import MetaTags from '../../../../components/seo/MetaTags/MetaTags';

interface PreferencesViewProps {}

const PreferencesView: FunctionComponent<PreferencesViewProps> = () => {
  return (
    <>
      <MetaTags title="Constellation Dinners - Preferences" hideMainTitle={true} />

      <HeaderSection title={`Preferences`} elementsToHide={['Avatar']} />

      <BaseSection></BaseSection>
    </>
  );
};

export default PreferencesView;
