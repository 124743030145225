import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import SectionDivider from '../../../../../../components/sections/SectionDivider/SectionDivider';
import { ReactComponent as TickIcon } from '../../../../../../asset/images/icons/tick_2.svg';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import EventCard from '../../../../components/blocks/EventCard/EventCard';
import MainButton from '../../../../../../components/buttons/MainButton/MainButton';
import NotificationCard from '../../../../components/blocks/NotificationCard/NotificationCard';
import styles from './Sorted.module.scss';
import TablematesBlock from '../../../../components/blocks/TablematesBlock/TablematesBlock';
import RestaurantDetailsBlock from '../../../../components/blocks/RestaurantDetailsBlock/RestaurantDetailsBlock';
import MapBlock from '../../../../components/blocks/MapBlock/MapBlock';

interface SortedProps {}

const Sorted: FunctionComponent<SortedProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  return (
    <>
      <HeaderSection title={`Hi, ${userFirstName}! 👋`} text="Ready to join your next dinner?" />

      <BaseSection>
        <EventCard
          title={'Wednesday, Oct 2 2024'}
          text={
            <>
              Get ready to kick off your summer with a bang at our fabulous Tech Innovators Networking Dinner Hosted by
              the ever-charming Saurav, this is your golden ticket to an hour of delicious food, great company, and
              unforgettable memories. Join us on July 5, 2024, from 10:30 AM to 11:30 AM in the heart of Austin, TX.
              Whether you're a foodie, a social butterfly, or just looking for a good time, this event is the perfect
              way to spice up your day. <br />
              <br />
              So mark your calendars, bring your appetite, and let's make this a morning to remember. Can't wait to see
              you there!
            </>
          }
          date="Friday, Jul 5 2024"
          time="10:30 am - 11:30am GMT-5"
          group_1_right={<MainButton iconLeft={<TickIcon style={{ width: '18px' }} />}>I'm going</MainButton>}
          bottomPart={
            <NotificationCard
              color="grey"
              content={
                <>
                  <b>You agreed to be the facilitator of this dinner.</b> Facilitators help ensure a smooth dinner
                  experience and get recognized as key contributors to our community.
                </>
              }
              group_1_right={
                <MainButton sizeType="medium" visualType="white">
                  Manage Preferences
                </MainButton>
              }
            />
          }
        />

        <TablematesBlock className={styles['tablemates']} />

        <div className={styles['section-type-1']}>
          <div className={styles['section-type-1__layout']}>
            <RestaurantDetailsBlock />

            <MapBlock />
          </div>
        </div>
      </BaseSection>
    </>
  );
};

export default Sorted;
