import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import { ReactComponent as DudeIll } from '../../../../../../asset/images/not_found/dude_with_telescope_and_waves.svg';
import styles from './Empty.module.scss';
import classNames from 'classnames';
import DudeSection from '../../../../components/sections/DudeSection/DudeSection';
import NavigationLink from '../../../../../../components/links/NavigationLink/NavigationLink';
import Grid from '../../../../../../components/blocks/Grid/Grid';
import EventPreviewCard from '../../../../components/blocks/EventPreviewCard/EventPreviewCard';
import { RoutesGrouped } from '../../../../../../data/routes';
import RoutesEnum from '../../../../../../enums/routes';
import { useNavigate } from 'react-router-dom';

interface PastEventsListProps {}

const PAST_EVENTS_TEST = Array.from({ length: 16 }, (_, i) => ({
  img: require('../../../../asset/images/rest_photo.jpg'),
  title: `Thursday, June 20, 2024`,
  time: '6:30 pm - 8:30 pm CST',
  location: 'Barley Swine',
  address: '6555 Burnet Rd #400, Austin, TX 78757, Estados Unidos',
  persons: [
    require('../../../../../../asset/images/avatar.png'),
    require('../../../../../../asset/images/avatar.png'),
    require('../../../../../../asset/images/avatar.png'),
    require('../../../../../../asset/images/avatar.png'),
    require('../../../../../../asset/images/avatar.png'),
  ],
}));

const PastEventsList: FunctionComponent<PastEventsListProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);
  const navigate = useNavigate();

  return (
    <>
      <HeaderSection title={`Past Events`} elementsToHide={['Avatar']} />

      <BaseSection>
        <Grid
          itemGroup={PAST_EVENTS_TEST.map((item, i) => (
            <EventPreviewCard
              imgUrl={item.img}
              title={item.title}
              time={item.time}
              locationName={item.location}
              locationAddress={item.address}
              avatarsUrl={item.persons}
              key={i}
              onClick={() =>
                navigate(`/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.PAST_EVENTS}/test-event`)
              }
            />
          ))}
        />
      </BaseSection>
    </>
  );
};

export default PastEventsList;
