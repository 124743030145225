import { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as ExitIcon } from '../../../../../asset/images/icons/exit.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../../../asset/images/icons/question_mark.svg';
import { ReactComponent as DotsIcon } from '../../../../../asset/images/icons/dots.svg';
import classNames from 'classnames';
import './AppHeader.css';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { ReactComponent as ConstellationDinnersLogo } from '../../../asset/images/constellation_dinners_logo.svg';
import BurgerButton from '../../../../../components/buttons/BurgerButton/BurgerButton';
import OnlyIconButton from '../../../../../components/buttons/OnlyIconButton/OnlyIconButton';
import MobileMenu from '../../../../../components/popups/MobileMenu/MobileMenu';
import RoutesEnum from '../../../../../enums/routes';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { logout } from '../../../../../utils/auth/logout';
import { LinkParams } from '../../../../../types/general';
import { RoutesGrouped } from '../../../../../data/routes';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';

const DINNERS_APP_NAV = [
  {
    text: 'Upcoming Event',
    url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.UPCOMING_EVENT}`,
  },
  {
    text: 'Past Events',
    url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.PAST_EVENTS}`,
  },
  {
    text: 'Preferences',
    url: `/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.PREFERENCES}`,
  },
] as LinkParams[];

type HeaderColorType = 'blue' | 'white';

const HeaderColorMap: Record<HeaderColorType, string> = {
  blue: '',
  white: 'header--color-white',
};

interface HeaderProps {
  headerColor?: HeaderColorType;
  [x: string]: any;
}

const AppHeader: FunctionComponent<HeaderProps> = ({ headerColor = 'white', ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  const isTablet = useMediaQuery('(max-width: 1279px)');
  const isMobile = useMediaQuery('(max-width: 767px)');

  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);

  const handleOnExit = () => logout(dispatch);

  const handleToggleMobileMenu = () => {
    setMobileMenuActive((state) => !state);
  };

  useEffect(() => {
    setMobileMenuActive(false);
  }, [pathname]);

  const renderAppButton = (className = 'el-app-button') => (
    <OnlyIconButton
      className={className}
      icon={<DotsIcon />}
      visualType={headerColor === 'white' ? 'white' : 'blank'}
    />
  );

  return (
    <>
      <header
        className={classNames(
          'header-app',
          HeaderColorMap[headerColor],
          'global__header',
          mobileMenuActive && 'header--fixed'
        )}
        {...rest}
      >
        <div className="background"></div>
        <div className="wrapper type-1">
          <div className="block-list flex middle space-between">
            <div className="block-item block-item-1 flex middle">
              {isTablet && (
                <BurgerButton
                  className="el-burger"
                  active={mobileMenuActive}
                  burgerColor={headerColor === 'blue' ? 'light' : 'dark'}
                  onClick={handleToggleMobileMenu}
                />
              )}

              {!isMobile && renderAppButton()}

              <NavLink
                className="el-logo"
                to={`/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.UPCOMING_EVENT}`}
              >
                <ConstellationDinnersLogo />
              </NavLink>

              {!isTablet && (
                <nav className="el-menu-nav">
                  <ul className="el-menu flex">
                    {DINNERS_APP_NAV.map((item, i) => (
                      <li>
                        <NavLink to={item.url} key={i}>
                          {item.text}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>

            <div className="block-item block-item-2 flex middle">
              {!isMobile && (
                <>
                  <MainButton iconLeft={<QuestionMarkIcon />} sizeType="medium" className="el-support-button">
                    Contact Support
                  </MainButton>
                </>
              )}

              <div className="el-logged-in-button-group-list flex middle">
                <NavLink
                  to={`/${RoutesGrouped.CONSTELLATION_DINNERS_BASE}/${RoutesEnum.UPCOMING_EVENT}`}
                  className="el-logged-in-button-group-avatar-container"
                >
                  <img
                    className="el-logged-in-button-group-avatar-container-image"
                    src={require('../../../../../asset/images/avatar.png')}
                    alt="avatar"
                  />

                  <p className="el-logged-in-button-group-avatar-container-name text text--body-2 text--bold">
                    {userFirstName}
                  </p>
                </NavLink>

                {isMobile === false && (
                  <OnlyIconButton
                    className="el-logged-in-button-group-exit-button"
                    icon={<ExitIcon />}
                    visualType={headerColor === 'white' ? 'white' : 'blank'}
                    onClick={handleOnExit}
                  />
                )}

                {isMobile && renderAppButton('el-app-button var-a')}
              </div>
            </div>
          </div>
        </div>
      </header>

      {mobileMenuActive && (
        <>
          {isTablet && (
            <MobileMenu
              customNavLinks={DINNERS_APP_NAV}
              onExit={handleOnExit}
              elementsToHide={['DashboardLink', 'RocketFuel', 'RoleSelector']}
            />
          )}
        </>
      )}
    </>
  );
};

export default AppHeader;
