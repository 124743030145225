import { FunctionComponent, useEffect } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_3.svg';
import classNames from 'classnames';
import styles from './ForgotPasswordChanged.module.scss';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { useNavigate, useOutletContext } from 'react-router-dom';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';
import { GENERAL_CONTENT } from '../../../../../data/generalContent';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import RoutesEnum from '../../../../../enums/routes';
import { localStorageGetItem, localStorageRemoveItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';

interface ForgotPasswordChangedProps {}

const ForgotPasswordChanged: FunctionComponent<ForgotPasswordChangedProps> = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width: 1279px)');

  const { togglePopUp } =
    useOutletContext<{
      togglePopUp;
    }>();

  useEffect(() => {
    if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_FORGOT_PASSWORD_STEP) !== '2') {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }
    localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_FORGOT_PASSWORD_STEP);
  }, []);

  return (
    <div className={styles['container']}>
      <MetaTags title="Password changed" />

      <div className={styles['container-top-part']}>
        <NavigationLink
          path="/"
          iconLeft={<ArrowIcon style={{ width: '11px', transform: 'rotate(180deg)' }} />}
          withBorder={false}
          colorType="white"
        >
          Back to Homepage
        </NavigationLink>
      </div>

      <div className={styles['content']}>
        <div className={styles['top-row']}>
          <div className={styles['astronomic-logo']}>{<AstronomicLogo />}</div>
          {isMobile === true && (
            <MainButton sizeType="medium" visualType="white" onClick={togglePopUp}>
              {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
            </MainButton>
          )}
        </div>

        <div className={styles['title']}>
          <h3 className="text text--h3">Password changed</h3>
        </div>

        <p className={classNames(styles['text'], 'text text--body-2')}>
          Your password has been successfully changed. Please sign in with your new credentials.
        </p>

        <MainButton
          className={styles['button']}
          onClick={() => {
            navigate(`/${RoutesEnum.SIGN_IN}`);
          }}
        >
          Sign In
        </MainButton>
      </div>

      <div className={styles['container-bottom-part']}></div>
    </div>
  );
};

export default ForgotPasswordChanged;
