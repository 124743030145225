import { FunctionComponent } from 'react';
import styles from './MapBlock.module.scss';
import { ReactComponent as LensIcon } from '../../../../../asset/images/icons/lens.svg';
import classNames from 'classnames';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';

interface MapBlockProps {
  className?: string;
}

const MapBlock: FunctionComponent<MapBlockProps> = ({ className }) => {
  return (
    <div className={classNames(styles['block'], 'tile tile--padding-32px', className)}>
      <div className={styles['top-part']}>
        <p className={classNames(styles['text-1'], 'text text--caption text--bold')}>Map & Directions</p>
      </div>

      <div className={styles['body-part']}>
        <p className={classNames(styles['text-2'], 'text text--body-2 text--bold')}>
          800 W Cesar Chavez St Ste PP110, Austin, TX 78701, United States
        </p>

        <div className={styles['map']}>
          <img src={require('../../../asset/images/google_map_dummy.jpg')} alt="Google Map" />
        </div>

        <div className={styles['button-list']}>
          <MainButton sizeType="medium">Get Directions</MainButton>
          <MainButton
            visualType="white"
            sizeType="medium"
            iconLeft={<LensIcon style={{ fill: 'var(--color-main)' }} />}
          >
            Find Parking Lot
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default MapBlock;
