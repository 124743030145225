import { FunctionComponent, useState } from 'react';
import HeaderSection from '../../components/sections/HeaderSection/HeaderSection';
import RSVP from './_parts/RSVP/RSVP';
import { useAppSelector } from '../../../../redux/hooks';
import Sorted from './_parts/Sorted/Sorted';
import DevTools from '../../../../components/devTools/DevToolStateToggler/DevToolStateToggler';
import MetaTags from '../../../../components/seo/MetaTags/MetaTags';
import CompleteProfile from './_parts/CompleteProfile/CompleteProfile';
import AfterDinner from './_parts/AfterDinner/AfterDinner';
import Empty from './_parts/Empty/Empty';

type ViewState = 'RSVP' | 'Sorted' | 'CompleteProfile' | 'AfterDinner' | 'Empty';

interface UpcomingEventViewProps {}

const UpcomingEventView: FunctionComponent<UpcomingEventViewProps> = () => {
  const [viewState, setViewState] = useState<ViewState>('RSVP');

  // DEV TOOLS
  const viewStates = [
    { label: 'RSVP', value: 'RSVP' },
    { label: 'Sorted', value: 'Sorted' },
    { label: 'Complete Profile', value: 'CompleteProfile' },
    { label: 'After Dinner', value: 'AfterDinner' },
    { label: 'Empty', value: 'Empty' },
  ];

  const handleViewStateChange = (newState: string) => {
    setViewState(newState as ViewState);
  };
  // DEV TOOLS END

  const renderContent = () => {
    switch (viewState) {
      case 'RSVP':
        return <RSVP />;
      case 'Sorted':
        return <Sorted />;
      case 'CompleteProfile':
        return <CompleteProfile />;
      case 'AfterDinner':
        return <AfterDinner />;
      case 'Empty':
        return <Empty />;

      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <>
      <MetaTags title="Constellation Dinners - Upcoming Event" hideMainTitle={true} />
      {renderContent()}
      <DevTools
        title="View State"
        states={viewStates}
        initialState={viewState}
        onChange={handleViewStateChange}
        position="top-left"
      />
    </>
  );
};

export default UpcomingEventView;
