import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_3.svg';
import classNames from 'classnames';
import styles from './CreatePassword.module.scss';
import Input from '../../../../../components/inputs/Input/Input';
import { ReactComponent as MailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { isEmailValid } from '../../../../../utils/regex';
import InputMessage from '../../../../../components/texts/InputMessage/InputMessage';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import api from '../../../../../api';
import { ApiResponseDTO } from '../../../../../dto/api';
import { AxiosError } from 'axios';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import { localStorageGetItem, localStorageSetItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';
import { GENERAL_CONTENT } from '../../../../../data/generalContent';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import PasswordForm from '../../../../../components/forms/PasswordForm/PasswordForm';
import { useAppDispatch } from '../../../../../redux/hooks';
import RoutesEnum from '../../../../../enums/routes';
import { login } from '../../../../../utils/auth/login';

interface CreatePasswordProps {}

const CreatePassword: FunctionComponent<CreatePasswordProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP) !== '3') {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }
  }, []);

  const handleOnSubmitApiRequest = async (password: string) => {
    let noErrors = true;
    let responseAccessToken = '';
    let responseRefreshToken = '';
    let responseUserId = '';

    const userId = localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID);
    const userEmail = localStorageGetItem(LOCAL_STORAGE_KEYS.USER_EMAIL);

    try {
      const response = await api.Auth.setUserPassword({
        userId: userId,
        password: password,
      });
      const responseData = response.data as ApiResponseDTO;

      if (responseData.success === true && responseData.data) {
        // LOGIN
        try {
          const response = await api.Auth.login({
            email: userEmail,
            password: password,
          });

          const responseData = response.data as ApiResponseDTO<{
            userId: string;
            refreshToken: string;
            token: string;
          }>;

          if (responseData.success === true && responseData.data) {
            responseAccessToken = responseData.data.token;
            responseRefreshToken = responseData.data.refreshToken;
            responseUserId = responseData.data.userId;
          } else {
            console.error('Error:', response.error);

            noErrors = false;
          }
        } catch (error) {
          console.error('Error: ', error);

          noErrors = false;
        }
        // LOGIN END

        if (noErrors === true) {
          localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '4');
          // localStorage.setItem('userSignUpStep', '4');

          login(dispatch, responseAccessToken, responseRefreshToken, responseUserId, {
            navigate: navigate,
            navigateUrl: `/${RoutesEnum.SIGN_UP}/${RoutesEnum.NAME_AND_ROLES}`,
          });

          return { success: true, message: 'All good. Proceeding to the next step...' };
        } else {
          return { success: false, message: 'Something went wrong. Please, try again later' };
        }
      } else {
        console.error('Error:', response.error);
        return { success: false, message: 'An error occurred.' };
      }
    } catch (error) {
      const errorObj = error as AxiosError<ApiResponseDTO>;
      const errorData = errorObj.response?.data;

      if (errorData?.errorMessage) {
        console.error('Error: ', errorData.errorMessage);
        return { success: false, message: errorData.errorMessage };
      }

      console.error('Error: ', error);
      return { success: false, message: 'An error occurred.' };
    }
  };

  const isMobile = useMediaQuery('(max-width: 1279px)');

  const { togglePopUp } =
    useOutletContext<{
      togglePopUp;
    }>();

  return (
    <div className={styles['container']}>
      <MetaTags title="Create password" />

      <div className={styles['container-top-part']}>
        <NavigationLink
          path="/"
          iconLeft={<ArrowIcon style={{ width: '11px', transform: 'rotate(180deg)' }} />}
          withBorder={false}
          colorType="white"
        >
          Back to Homepage
        </NavigationLink>
      </div>

      <div className={styles['content']}>
        <div className={styles['top-row']}>
          <div className={styles['astronomic-logo']}>{<AstronomicLogo />}</div>
          {isMobile === true && (
            <MainButton sizeType="medium" visualType="white" onClick={togglePopUp}>
              {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
            </MainButton>
          )}
        </div>

        <div className={styles['title']}>
          <h3 className="text text--h3">Choose a password</h3>

          <div className={styles['form']}>
            <PasswordForm onSubmitApiRequest={handleOnSubmitApiRequest} emailFieldActive={false} />
          </div>
        </div>
      </div>

      <div className={styles['container-bottom-part']}></div>
    </div>
  );
};

export default CreatePassword;
