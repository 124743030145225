import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import MainHeader from '../../../components/headers/MainHeader/MainHeader';
import classNames from 'classnames';
import with_user_role, { IWithUserRoleProps } from '../../hocs/main/WithUserRole';
import { UserRoleEnum } from '../../../enums/user';
import MainFooter from '../../../components/footers/MainFooter/MainFooter';
import FooterSection, { FooterSectionProps } from '../../../components/sections/FooterSection/FooterSection';
import SectionDivider from '../../../components/sections/SectionDivider/SectionDivider';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import API from '../../../api/index';
import { useAppSelector } from '../../../redux/hooks';
import RoutesEnum from '../../../enums/routes';
import { Params, useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';
import HeaderBar from '../../../components/headers/HeaderBarLaunch/HeaderBarLaunch';
import StartupSection from '../../../components/sections/StartupSection/StartupSection';
import styles from './MainTemplate.module.scss';
import { GENERAL_CONTENT } from '../../../data/generalContent';

interface MainTemplateForMainPagesProps
  extends PropsWithChildren,
    IWithUserRoleProps,
    Pick<FooterSectionProps, 'footerSectionVariation'> {
  color?: 'white' | 'blue';
  hideFooterSection?: boolean;
  user_role: UserRoleEnum;
}

const MainTemplateForMainPages: FC<MainTemplateForMainPagesProps> = ({
  children,
  color = 'blue',
  footerSectionVariation = 'var-0',
  hideFooterSection = false,
  user_role,
  router,
}) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const { role } = useParams<Params>();
  const location = useLocation();
  const navigate = useNavigate();
  const [isHome, setIsHome] = useState<Boolean>(false);
  const [startAnimation, setStartAnimation] = useState<Boolean>(false);

  // const headerRef = useRef<HTMLElement>(null);
  // const headerBarRef = useRef<HTMLElement>(null);

  // Function to check if the URL matches exactly "/:role"
  const isExactRolePath = () => {
    const pathSegments = location.pathname.split('/').filter((segment) => segment);
    return pathSegments.length === 1 && !!role;
  };

  useEffect(() => {
    if (isExactRolePath()) {
      setIsHome(true);
    }
  }, [isHome]);

  return (
    <div
      className={classNames(
        styles['main-template'],
        startAnimation && styles['main-template--startup-animation-active'],
        'template main-template'
      )}
    >
      {isHome && <HeaderBar setStartAnimation={setStartAnimation} />}

      <HeaderBar setStartAnimation={setStartAnimation} userRole={user_role} />

      <MainHeader user_role={user_role} headerColor={color} loggedIn={isUserLoggedIn} />

      <main>
        <StartupSection
          title="Your one-stop startup shop for"
          text=""
          userRole={user_role}
          startAnimation={startAnimation}
          skipToRocket={true}
          setStartAnimation={setStartAnimation}
          className={classNames(styles['startup-section'], styles['startup-section--main-pages'])}
          buttonGroup={[
            isUserLoggedIn === false ? (
              <MainButton
                sizeType={'large'}
                visualType="main"
                onClick={() => {
                  navigate(`/${RoutesEnum.SIGN_UP}`);
                }}
              >
                {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
              </MainButton>
            ) : (
              <MainButton
                sizeType={'large'}
                visualType="main"
                onClick={() => {
                  navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
                }}
              >
                {GENERAL_CONTENT.SCHEDULE_A_MEETING}
              </MainButton>
            ),
            <MainButton sizeType={'large'} visualType="blank">
              {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
            </MainButton>,
          ]}
        />

        <div className={classNames(styles['children'], 'global__children')}>
          {children}

          {hideFooterSection === false && startAnimation === false && (
            <>
              <SectionDivider color="blue" />

              <FooterSection
                title="No one goes to the moon alone"
                text="Astronomic is your copilot for accelerating every step of your journey to the moon."
                buttonGroup={[
                  isUserLoggedIn === false ? (
                    <MainButton
                      sizeType={'large'}
                      visualType="main"
                      onClick={() => {
                        router.navigate(`/${RoutesEnum.SIGN_UP}`);
                      }}
                    >
                      Join Astronomic for free
                    </MainButton>
                  ) : (
                    <MainButton
                      sizeType={'large'}
                      visualType="main"
                      onClick={() => {
                        router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
                      }}
                    >
                      Schedule a meeting
                    </MainButton>
                  ),
                  <MainButton
                    sizeType={'large'}
                    visualType="blank"
                    onClick={() => {
                      router.navigate(`/${user_role}/${RoutesEnum.ABOUT}`);
                    }}
                  >
                    About Us
                  </MainButton>,
                ]}
                footerSectionVariation={footerSectionVariation}
              />
            </>
          )}
        </div>
      </main>

      {startAnimation === false && <MainFooter userRole={user_role} />}
    </div>
  );
};

export default with_user_role(MainTemplateForMainPages);
