import { FunctionComponent } from 'react';
import styles from './BaseSection.module.scss';
import BackgroundSectionTemplate from '../../../../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import classNames from 'classnames';

interface BaseSectionProps extends React.PropsWithChildren {
  className?: string;
}

const BaseSection: FunctionComponent<BaseSectionProps> = ({ children, className }) => {
  return (
    <section className={classNames(styles['section'], className)}>
      <BackgroundSectionTemplate color="Background-Neutral-Primary"></BackgroundSectionTemplate>

      <div className={classNames('wrapper type-1')}>
        <div className="content">{children}</div>
      </div>
    </section>
  );
};

export default BaseSection;
