export enum LOCAL_STORAGE_KEYS {
  USER_ID = 'userId',
  ACCESS_TOKEN = 'AT',
  REFRESH_TOKEN = 'RT',
  USER_FIRST_NAME = 'userFirstName',
  USER_EMAIL = 'userEmail',
  USER_FORGOT_PASSWORD_STEP = 'userForgotPasswordStep',
  USER_SIGN_UP_STEP = 'userSignUpStep',
  ROLE_TOOLTIP_FIRST_VISIT = 'roleTooltipFirstVisit',
  CURRENT_ROLE = 'currentRole',
}
