import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './EventCard.module.scss';
import { To, useNavigate } from 'react-router-dom';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import { ReactComponent as CalendarIcon } from '../../../../../asset/images/icons/calendar_2.svg';
import { ReactComponent as TimeIcon } from '../../../../../asset/images/icons/time.svg';
import GroupWithGap from '../../../../../components/lists/GroupWithGap/GroupWithGap';

export interface EventCardProps {
  title: string | React.ReactElement;
  text: string | React.ReactElement;
  size?: 'original' | 'small';
  date?: string;
  time?: string;
  group_1_right?: React.ReactElement;
  bottomPart?: React.ReactElement;
  disabled?: boolean;
  disableHover?: boolean;
  className?: string;
}

const EventCard: FunctionComponent<EventCardProps> = ({
  title,
  text,
  size = 'original',
  date,
  time,
  disabled = false,
  group_1_right,
  bottomPart,
  disableHover = true,
  className,
}) => {
  return (
    <article
      className={classNames(
        styles['event-card'],
        styles[`event-card--size-${size}`],
        disabled && styles[`event-card--disabled`],
        disableHover && styles[`event-card--disable-hover`],
        !disableHover && 'button-container-for-hover',
        className
      )}
    >
      <div className={styles['top-part']}>
        <div className={styles['group-1']}>
          <div className={styles['group-1__left']}>
            <div className={styles['title']}>
              <h4 className="text text--h4">{title}</h4>
            </div>
          </div>

          {group_1_right && <div className={styles['group-1__right']}>{group_1_right}</div>}
        </div>

        {date || time ? (
          <div className={styles['group-2']}>
            {date && (
              <p className={classNames(styles['date-time'], 'text text--body-3')}>
                <span className={styles['date-time__icon']}>
                  <CalendarIcon style={{ fill: 'var(--color-main)', maxWidth: '12px' }} />
                </span>
                <span className={styles['date-time__text']}>{date}</span>
              </p>
            )}

            {time && (
              <p className={classNames(styles['date-time'], 'text text--body-3')}>
                <span className={styles['date-time__icon']}>
                  <TimeIcon style={{ fill: 'var(--color-main)', maxWidth: '15px' }} />
                </span>
                <span className={styles['date-time__text']}>{time}</span>
              </p>
            )}
          </div>
        ) : null}

        <div className={styles['text']}>
          <p className="text text--body-3">{text}</p>
        </div>
      </div>

      {bottomPart && <div className={styles['bottom-part']}>{bottomPart}</div>}
    </article>
  );
};

export default EventCard;
