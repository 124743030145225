import { FunctionComponent } from 'react';
import classNames from 'classnames';
import BackgroundSectionTemplate from '../../../../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import { ReactComponent as ContDinnersIll } from '../../../../../asset/images/connect_ill_1.svg';
import styles from './HeaderSection.module.scss';
import { useNavigate } from 'react-router-dom';
import SectionDivider from '../../../../../components/sections/SectionDivider/SectionDivider';

type UiElements = 'Avatar' | 'Breadcrumbs' | 'RightPart';

export interface HeaderSectionProps {
  title?: string;
  subTitle?: string;
  text?: string | React.ReactElement;
  elementsToHide?: UiElements[];
}

const HeaderSection: FunctionComponent<HeaderSectionProps> = ({ title, subTitle, text, elementsToHide = [] }) => {
  const navigate = useNavigate();

  return (
    <>
      <section className={styles['account-header']}>
        <BackgroundSectionTemplate className={styles['account-header__bg']} color={'blue'} />

        <div className={classNames('wrapper', 'type-1')}>
          <div className="content">
            <div className={styles['block-list']}>
              <div className={styles['block-list__item-1']}>
                {!elementsToHide.includes('Avatar') && (
                  <div className={styles['avatar']}>
                    <img src={require('../../../../../asset/images/avatar.png')} alt="Avatar" />
                  </div>
                )}

                <div className={styles['account-info']}>
                  <div className={styles['account-info__name-container']}>
                    {subTitle && (
                      <p className={classNames(styles['account-info__name-container__text-2'], 'text text--caps')}>
                        {subTitle}
                      </p>
                    )}

                    <h1 className={classNames(styles['account-info__name-container__text'], 'text text--h2')}>
                      {title}
                    </h1>
                  </div>

                  {text && (
                    <div className={styles['account-info__location-container']}>
                      <p className={classNames(styles['account-info__location-container__text'], 'text text--body-1')}>
                        {text}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {!elementsToHide.includes('RightPart') && (
                <div className={styles['block-list__item-2']}>
                  <div className={styles['ill']}>
                    <ContDinnersIll />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <SectionDivider color="Background-Neutral-Primary" />
    </>
  );
};

export default HeaderSection;
