import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './FooterSection.module.scss';
import { ReactComponent as Illustration } from '../../../asset/images/cd_main_ill.svg';
import { ReactComponent as BgElements } from '../../../../../asset/images/bg_elements.svg';

export interface FooterSectionProps {
  title: string;
  subTitle?: string;
  text: string;
  buttonGroup?: React.ReactElement[];
}

const FooterSection: FunctionComponent<FooterSectionProps> = ({ title, subTitle, text, buttonGroup }) => {
  return (
    <section className={classNames(styles['section'])}>
      <div className="background">
        <div className={classNames(styles['bg-elements'])}>
          <BgElements />
        </div>
      </div>

      <div className="wrapper">
        <div className="content">
          <div className={classNames(styles['block-list'])}>
            <div className={classNames(styles['block-list__item-1'])}>
              {subTitle && (
                <div className={classNames(styles['sub-title'])}>
                  <h2 className="text text--caps">{subTitle}</h2>
                </div>
              )}

              <div className={classNames(styles['title'])}>
                <h2 className="text text--h2">{title}</h2>
              </div>

              <div className={classNames(styles['text'])}>
                <p className="text text--body-2">{text}</p>
              </div>

              {buttonGroup && (
                <div className={styles['button-group']}>
                  {buttonGroup.map((item, i) => (
                    <div className={styles['button-group__item']} key={i}>
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={classNames(styles['block-list__item-2'])}>
              <div className={classNames(styles['image'])}>
                <Illustration />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
