import { FunctionComponent } from 'react';
import { ReactComponent as DudeIll } from '../../../../../asset/images/not_found/dude_with_telescope_and_waves.svg';
import styles from './DudeSection.module.scss';
import classNames from 'classnames';
import BaseSection from '../BaseSection/BaseSection';

interface DudeSectionProps extends React.PropsWithChildren {
  className?: string;
}

const DudeSection: FunctionComponent<DudeSectionProps> = ({ children, className }) => {
  return (
    <BaseSection className={classNames(styles['section'], className)}>
      <div className={classNames(styles['content'], 'text text--body-1')}>{children}</div>

      <div className={styles['dude']}>
        <div className={styles['dude__inner']}>
          <DudeIll />
        </div>
      </div>
    </BaseSection>
  );
};

export default DudeSection;
