import { FunctionComponent } from 'react';
import HeaderSection from '../../components/sections/HeaderSection/HeaderSection';
import BaseSection from '../../components/sections/BaseSection/BaseSection';
import MetaTags from '../../../../components/seo/MetaTags/MetaTags';

interface OnboardingPreferencesViewProps {}

const OnboardingPreferencesView: FunctionComponent<OnboardingPreferencesViewProps> = () => {
  return (
    <>
      <MetaTags title="Constellation Dinners - Dinning Preferences" hideMainTitle={true} />

      <HeaderSection
        title={`Your dinner, your preferences`}
        subTitle={`Constellation Dinners`}
        text={
          <span style={{ color: 'var(--color-gray-500)' }}>
            Help us customize your Constellation Dinner by sharing a few key details. Your preferences ensure you’re
            matched with the right group and have an enjoyable experience.
          </span>
        }
        elementsToHide={['Avatar', 'RightPart']}
      />

      <BaseSection></BaseSection>
    </>
  );
};

export default OnboardingPreferencesView;
