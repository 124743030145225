import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './NotificationCard.module.scss';
import { To, useNavigate } from 'react-router-dom';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import { ReactComponent as InfoIcon } from '../../../../../asset/images/icons/info.svg';
import GroupWithGap from '../../../../../components/lists/GroupWithGap/GroupWithGap';

export interface NotificationCardProps {
  content: string | React.ReactElement;
  customIcon?: React.ReactElement;
  group_1_right?: React.ReactElement;
  color?: 'white' | 'grey';
  className?: string;
}

const NotificationCard: FunctionComponent<NotificationCardProps> = ({
  content,
  customIcon,
  color,
  group_1_right,
  className,
}) => {
  return (
    <article
      className={classNames(styles['notification-card'], styles[`notification-card--color-${color}`], className)}
    >
      <div className={styles['group-1']}>
        <div className={styles['group-1__left']}>
          <div className={styles['content']}>
            <span className={styles['content__icon']}>
              {customIcon ? customIcon : <InfoIcon style={{ fill: 'var(--color-main)', width: '22px' }} />}
            </span>
            <span className={classNames(styles['content__text'], 'text text--body-3')}>{content}</span>
          </div>
        </div>

        {group_1_right && <div className={styles['group-1__right']}>{group_1_right}</div>}
      </div>
    </article>
  );
};

export default NotificationCard;
