import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TimeIcon } from '../../../../../asset/images/icons/time.svg';
import { ReactComponent as PinIcon } from '../../../../../asset/images/icons/pin.svg';
import styles from './EventPreviewCard.module.scss';
import classNames from 'classnames';

interface EventPreviewCardProps {
  imgUrl?: string;
  title?: string;
  time?: string;
  locationName?: string;
  locationAddress?: string;
  avatarsUrl?: string[];
  onClick?(): void;
}

const EventPreviewCard: FunctionComponent<EventPreviewCardProps> = ({
  imgUrl,
  title,
  time,
  locationName,
  locationAddress,
  avatarsUrl,
  onClick,
}) => {
  return (
    <article
      className={styles['article']}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div className={styles['article__inner']}>
        <div className={styles['top-part']}>
          {imgUrl && (
            <div className={styles['image']}>
              <img src={imgUrl} alt="Past event preview" />
            </div>
          )}
        </div>

        <div className={styles['main-part']}>
          {title && <p className={classNames(styles['title'], 'text text--h4')}>{title}</p>}

          {time && (
            <div className={styles['time']}>
              <div className={styles['time__icon']}>
                <TimeIcon style={{ fill: 'var(--color-main)', width: '24px' }} />
              </div>

              <p className={classNames(styles['time__text'], 'text text--body-3')}>{time}</p>
            </div>
          )}

          {locationName && (
            <div className={styles['location']}>
              <div className={styles['location__icon']}>
                <PinIcon style={{ fill: 'var(--color-main)', width: '24px' }} />
              </div>

              <div className={styles['location__info']}>
                <p className={classNames(styles['location__text-1'], 'text text--body-2 text--bold')}>{locationName}</p>
                <p className={classNames(styles['location__text-2'], 'text text--body-3')}>{locationAddress}</p>
              </div>
            </div>
          )}

          {avatarsUrl && (
            <div className={styles['avatar-list']}>
              {avatarsUrl.map((item, i) => (
                <div className={styles['avatar-list__item']} key={i}>
                  <img src={item} alt="Avatar" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default EventPreviewCard;
