import { FunctionComponent, useState } from 'react';
import HeaderSection from '../../components/sections/HeaderSection/HeaderSection';
import { useAppSelector } from '../../../../redux/hooks';
import DevTools from '../../../../components/devTools/DevToolStateToggler/DevToolStateToggler';
import MetaTags from '../../../../components/seo/MetaTags/MetaTags';
import Empty from './_parts/Empty/Empty';
import PastEventsList from './_parts/PastEventsList/PastEventsList';

type ViewState = 'PastEvents' | 'Empty';

interface PastEventsViewProps {}

const PastEventsView: FunctionComponent<PastEventsViewProps> = () => {
  const [viewState, setViewState] = useState<ViewState>('PastEvents');

  // DEV TOOLS
  const viewStates = [
    { label: 'Past Events', value: 'PastEvents' },
    { label: 'Empty', value: 'Empty' },
  ];

  const handleViewStateChange = (newState: string) => {
    setViewState(newState as ViewState);
  };
  // DEV TOOLS END

  const renderContent = () => {
    switch (viewState) {
      case 'PastEvents':
        return <PastEventsList />;
      case 'Empty':
        return <Empty />;

      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <>
      <MetaTags title="Constellation Dinners - Past Events" hideMainTitle={true} />
      {renderContent()}
      <DevTools
        title="View State"
        states={viewStates}
        initialState={viewState}
        onChange={handleViewStateChange}
        position="top-left"
      />
    </>
  );
};

export default PastEventsView;
