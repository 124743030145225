import React from 'react';
import withRouter, { IWithRouterProps } from '../basic/withRouter';
import { is_user_role } from '../../../type_guards/user_role';
import { UserRoleEnum } from '../../../../src/enums/user';
import NotFoundView from '../../../views/NotFoundView/NotFoundView';
import { localStorageGetItem, localStorageSetItem } from '../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../enums/localStorageKeys';

export type IWithUserRoleProps = {
  user_role: UserRoleEnum;
} & WithUserRoleProps;

interface WithUserRoleProps extends IWithRouterProps {}

interface WithUserRoleState {}

const with_user_role = (WrappedComponent: any) => {
  class WithUserRole extends React.Component<WithUserRoleProps, WithUserRoleState> {
    constructor(props: WithUserRoleProps) {
      super(props);
      this.state = {};
    }

    render() {
      const role = this.props.router.params.role;
      const userRoleDefined = is_user_role(role);

      const setUserRoleToLocalStorage = () => {
        if (typeof role === 'undefined') return;
        if (userRoleDefined === false) return;
        if (localStorageGetItem(LOCAL_STORAGE_KEYS.CURRENT_ROLE) === null) return;
        if (localStorageGetItem(LOCAL_STORAGE_KEYS.CURRENT_ROLE) === role) return;

        localStorageSetItem(LOCAL_STORAGE_KEYS.CURRENT_ROLE, role);
      };

      setUserRoleToLocalStorage();

      return userRoleDefined ? <WrappedComponent {...this.props} user_role={role} /> : <NotFoundView />;
    }
  }

  return withRouter(WithUserRole);
};

export default with_user_role;
