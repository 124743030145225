import { FunctionComponent } from 'react';
import HeaderSection from '../../components/sections/HeaderSection/HeaderSection';
import BaseSection from '../../components/sections/BaseSection/BaseSection';
import EventCard from '../../components/blocks/EventCard/EventCard';
import TablematesBlock from '../../components/blocks/TablematesBlock/TablematesBlock';
import styles from './PastEventsDetailView.module.scss';
import RestaurantDetailsBlock from '../../components/blocks/RestaurantDetailsBlock/RestaurantDetailsBlock';
import MapBlock from '../../components/blocks/MapBlock/MapBlock';
import MetaTags from '../../../../components/seo/MetaTags/MetaTags';

interface PastEventsDetailViewProps {}

const PastEventsDetailView: FunctionComponent<PastEventsDetailViewProps> = () => {
  return (
    <>
      <MetaTags title="Constellation Dinners - Past Event" hideMainTitle={true} />

      <HeaderSection title={`Thursday, June 20, 2024`} elementsToHide={['Avatar']} />

      <BaseSection>
        <EventCard
          title={'Event Details'}
          text={
            <>
              Get ready to kick off your summer with a bang at our fabulous Tech Innovators Networking Dinner Hosted by
              the ever-charming Saurav, this is your golden ticket to an hour of delicious food, great company, and
              unforgettable memories. Join us on July 5, 2024, from 10:30 AM to 11:30 AM in the heart of Austin, TX.
              Whether you're a foodie, a social butterfly, or just looking for a good time, this event is the perfect
              way to spice up your day. <br />
              <br />
              So mark your calendars, bring your appetite, and let's make this a morning to remember. Can't wait to see
              you there!
            </>
          }
          date="Friday, Jul 5 2024"
          time="10:30 am - 11:30am GMT-5"
        />

        <TablematesBlock className={styles['tablemates']} />

        <div className={styles['section-type-1']}>
          <div className={styles['section-type-1__layout']}>
            <RestaurantDetailsBlock />

            <MapBlock />
          </div>
        </div>
      </BaseSection>
    </>
  );
};

export default PastEventsDetailView;
