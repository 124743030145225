import { FunctionComponent } from 'react';
import HeaderSection from '../../../../components/sections/HeaderSection/HeaderSection';
import { useAppSelector } from '../../../../../../redux/hooks';
import BaseSection from '../../../../components/sections/BaseSection/BaseSection';
import { ReactComponent as DudeIll } from '../../../../../../asset/images/not_found/dude_with_telescope_and_waves.svg';
import styles from './Empty.module.scss';
import classNames from 'classnames';
import DudeSection from '../../../../components/sections/DudeSection/DudeSection';
import NavigationLink from '../../../../../../components/links/NavigationLink/NavigationLink';

interface EmptyProps {}

const Empty: FunctionComponent<EmptyProps> = () => {
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);

  return (
    <>
      <HeaderSection
        title={`Hi, ${userFirstName}! 👋`}
        text="Complete your profile to start receiving dinner invitations."
      />

      <DudeSection>
        Looks like you haven’t attended any Constellation Dinners yet! Once you’ve joined a dinner, your past events
        will appear here. Until then, <b>keep an eye out for your next invitation</b> and get ready for great
        conversations and connections. If you need assistance or have any questions, feel free to{' '}
        <NavigationLink path="#">reach out to Karla</NavigationLink>!
      </DudeSection>
    </>
  );
};

export default Empty;
