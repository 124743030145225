import { FunctionComponent } from 'react';
import styles from './RestaurantDetailsBlock.module.scss';
import { ReactComponent as StarFullIcon } from '../../../../../asset/images/icons/star_3.svg';
import { ReactComponent as StarEmptyIcon } from '../../../../../asset/images/icons/star_3_empty.svg';
import classNames from 'classnames';
import GroupWithGap from '../../../../../components/lists/GroupWithGap/GroupWithGap';
import Label from '../../../../../components/texts/Label/Label';
import Slider from '../../../../../components/lists/Slider/Slider';

interface RestaurantDetailsBlockProps {
  className?: string;
}

const STAR_COUNT = 5;

const TEST_TAG_DATA = ['Outdoor seating', 'Dine-in', 'Great dessert', 'Rooftop seating', 'Cosy'];

const RestaurantDetailsBlock: FunctionComponent<RestaurantDetailsBlockProps> = ({ className }) => {
  const averageRating = '4.9';
  const averageRoundDownRating = parseInt(averageRating);

  return (
    <div className={classNames(styles['block'], 'tile tile--padding-32px', className)}>
      <div className={styles['top-part']}>
        <p className={classNames(styles['text-1'], 'text text--caption text--bold')}>Restaurant Details</p>
      </div>

      <div className={styles['body-part']}>
        <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>Ember Kitchen</p>

        <div className={styles['group-1']}>
          <div className={styles['group-1__item']}>
            <div className={styles['star-rating']}>
              <p className={classNames(styles['star-rating__average'], 'text text--caption')}>{averageRating}</p>
              <div className={styles['star-rating__list']}>
                {Array.from({ length: STAR_COUNT }).map((item, i) => (
                  <div className={styles['star-rating__list__item']} key={i}>
                    {i >= averageRoundDownRating ? <StarEmptyIcon /> : <StarFullIcon />}
                  </div>
                ))}
              </div>
              <p className={classNames(styles['star-rating__votes'], 'text text--caption')}>(1,030)</p>
            </div>
          </div>

          <div className={styles['group-1__item']}>
            <p className={classNames(styles['star-rating__cuisine'], 'text text--caption')}>
              Latin American restaurant
            </p>
          </div>
        </div>

        <GroupWithGap
          className={styles['tag-list']}
          items={TEST_TAG_DATA.map((item, i) => (
            <Label text={item} visualStyle="main-rounded" />
          ))}
        ></GroupWithGap>

        <Slider
          visualType="images"
          sliderSettingsProp={{ dots: false, arrows: false }}
          className={styles['slider']}
          sliderSlides={[
            <div className={styles['slider__item']}>
              <img
                className={styles['slider__item__image']}
                src={require('../../../asset/images/rest_photo.jpg')}
                alt="restaurant"
              />
            </div>,
            <div className={styles['slider__item']}>
              <img
                className={styles['slider__item__image']}
                src={require('../../../asset/images/rest_photo.jpg')}
                alt="restaurant"
              />
            </div>,
            <div className={styles['slider__item']}>
              <img
                className={styles['slider__item__image']}
                src={require('../../../asset/images/rest_photo.jpg')}
                alt="restaurant"
              />
            </div>,
            <div className={styles['slider__item']}>
              <img
                className={styles['slider__item__image']}
                src={require('../../../asset/images/rest_photo.jpg')}
                alt="restaurant"
              />
            </div>,
          ]}
        />
      </div>
    </div>
  );
};

export default RestaurantDetailsBlock;
