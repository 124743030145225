import { FunctionComponent } from 'react';
import HeaderSection from '../../components/sections/HeaderSection/HeaderSection';
import BaseSection from '../../components/sections/BaseSection/BaseSection';
import MetaTags from '../../../../components/seo/MetaTags/MetaTags';
import { ReactComponent as MarkIcon } from '../../../../asset/images/icons/alert.svg';
import { ReactComponent as PeopleIcon } from '../../../../asset/images/icons/people.svg';
import { ReactComponent as ChatIcon } from '../../../../asset/images/icons/chat.svg';
import { ReactComponent as EyeIcon } from '../../../../asset/images/icons/eye_2.svg';
import { ReactComponent as HandShakeIcon } from '../../../../asset/images/icons/handshake.svg';
import { ReactComponent as Chat2Icon } from '../../../../asset/images/icons/chat_2.svg';
import classNames from 'classnames';
import styles from './CodeOfConductView.module.scss';
import MainButton from '../../../../components/buttons/MainButton/MainButton';
import { CLIENT_SUPPORT_CONTENT } from '../../data/clientSupport';

interface CodeOfConductViewProps {}

const CodeOfConductView: FunctionComponent<CodeOfConductViewProps> = () => {
  return (
    <>
      <MetaTags title="Constellation Dinners - Dinning Preferences" hideMainTitle={true} />

      <HeaderSection
        title={`Code of Conduct`}
        subTitle={`Constellation Dinners`}
        elementsToHide={['Avatar', 'RightPart']}
      />

      <BaseSection>
        <p className={classNames(styles['text-1'], 'text text--body-1')}>
          At Constellation Dinners, we believe in fostering a <b>professional</b>, <b>inclusive</b>, and{' '}
          <b>respectful environment</b> for all members. Our dinners are designed to facilitate meaningful connections
          between founders and investors, and we expect everyone to uphold high standards of behavior to ensure a
          positive experience for all attendees. By participating in Constellation Dinners, you agree to the following
          Code of Conduct:
        </p>

        <div className={styles['list']}>
          <div className={styles['list__item']}>
            <div className={styles['list__item__icon']}>
              <MarkIcon style={{ fill: 'var(--color-main)', width: '32px' }} />
            </div>

            <div className={styles['list__item__info']}>
              <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>1. Respectful Behavior</p>
              <p className={classNames(styles['text-3'], 'text text--body-2')}>
                All participants are expected to treat others with respect and dignity. Harassment, discrimination, and
                inappropriate behavior of any kind will not be tolerated. This includes, but is not limited to:
                <ul>
                  <li>
                    <b>Sexual harassment:</b> Unwelcome sexual advances, inappropriate physical contact, or suggestive
                    comments.
                  </li>

                  <li>
                    <b>Solicitation:</b> Unwanted requests for business, personal favors, or inappropriate promotions
                    unrelated to the context of the dinner.
                  </li>

                  <li>
                    <b>Bullying or intimidation:</b> Aggressive or threatening behavior towards other participants.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className={styles['list__item']}>
            <div className={styles['list__item__icon']}>
              <PeopleIcon style={{ fill: 'var(--color-main)', width: '32px' }} />
            </div>

            <div className={styles['list__item__info']}>
              <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>
                2. Professionalism and Courtesy
              </p>
              <p className={classNames(styles['text-3'], 'text text--body-2')}>
                Each member is expected to conduct themselves professionally and courteously. This means:
                <ul>
                  <li>Refraining from arrogance, ego, or excessive self-promotion.</li>

                  <li>
                    Avoiding loudness, interruptions, or disruptive behavior that detracts from the group’s experience.
                  </li>

                  <li>
                    No name-calling, belittling, or personal attacks. Constructive criticism is encouraged, but it must
                    be delivered respectfully.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className={styles['list__item']}>
            <div className={styles['list__item__icon']}>
              <ChatIcon style={{ fill: 'var(--color-main)', width: '32px' }} />
            </div>

            <div className={styles['list__item__info']}>
              <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>
                3. Inclusivity and Openness
              </p>
              <p className={classNames(styles['text-3'], 'text text--body-2')}>
                We encourage an environment where all voices are heard. Discrimination based on race, gender, ethnicity,
                nationality, religion, sexual orientation, or any other protected characteristic is strictly prohibited.
                Members are encouraged to listen as much as they speak and to be open to diverse perspectives.
              </p>
            </div>
          </div>

          <div className={styles['list__item']}>
            <div className={styles['list__item__icon']}>
              <EyeIcon style={{ fill: 'var(--color-main)', width: '32px' }} />
            </div>

            <div className={styles['list__item__info']}>
              <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>4. Confidentiality</p>
              <p className={classNames(styles['text-3'], 'text text--body-2')}>
                Conversations during Constellation Dinners may involve sensitive topics related to business strategies,
                investments, or personal stories. Members are expected to respect the confidentiality of these
                discussions and refrain from sharing information outside the group without explicit permission.
              </p>
            </div>
          </div>

          <div className={styles['list__item']}>
            <div className={styles['list__item__icon']}>
              <HandShakeIcon style={{ stroke: 'var(--color-main)', width: '32px' }} />
            </div>

            <div className={styles['list__item__info']}>
              <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>5. Constructive Engagement</p>
              <p className={classNames(styles['text-3'], 'text text--body-2')}>
                Constellation Dinners are designed to foster thoughtful, high-level discussions between founders and
                investors. We encourage constructive dialogue, collaborative brainstorming, and a focus on value
                creation for all parties. Self-centered or overly transactional behavior that detracts from the group’s
                dynamic is discouraged.
              </p>
            </div>
          </div>

          <div className={styles['list__item']}>
            <div className={styles['list__item__icon']}>
              <Chat2Icon style={{ stroke: 'var(--color-main)', width: '32px' }} />
            </div>

            <div className={styles['list__item__info']}>
              <p className={classNames(styles['text-2'], 'text text--body-1 text--bold')}>6. Addressing Misconduct</p>
              <p className={classNames(styles['text-3'], 'text text--body-2')}>
                If you witness or experience any form of misconduct, please report it immediately to the Constellation
                Dinners organizing team. We are committed to ensuring a safe and respectful space for all participants,
                and we will take appropriate action to address any violations of this Code of Conduct.
              </p>
            </div>
          </div>
        </div>

        <p className={classNames(styles['text-4'], 'text text--body-1')}>
          By attending Constellation Dinners, you acknowledge that you have read and agree to adhere to this Code of
          Conduct. Violations may result in removal from current and future events, as well as membership termination.
        </p>

        <div className={classNames(styles['support'], 'tile tile--padding-32px')}>
          <div className={styles['support__left']}>
            <div className={styles['support__avatar']}>
              <img src={CLIENT_SUPPORT_CONTENT.ava_1} alt="Support avatar" />
            </div>
          </div>

          <div className={styles['support__right']}>
            <p className={classNames(styles['text-5'], 'text text--body-1')}>
              {CLIENT_SUPPORT_CONTENT.codeOfConductContent}
            </p>
          </div>
        </div>

        <MainButton className={styles['button']}>Finish</MainButton>
      </BaseSection>
    </>
  );
};

export default CodeOfConductView;
