import NavigationLink from '../../../components/links/NavigationLink/NavigationLink';

const EMAIL = 'karla@constellationdinners.com';
const TEL = '(555) 123-4567';
const NAME = 'Karla';
const AVA_1 = require('../asset/images/support_avatar_1.jpg');

export const CLIENT_SUPPORT_CONTENT = {
  email: EMAIL,
  tel: TEL,
  name: NAME,
  ava_1: AVA_1,
  codeOfConductContent: (
    <>
      If you have any questions or need more info, {NAME} is available to help. Reach out to her via email at
      <NavigationLink path={`mailto:${EMAIL}`}>{EMAIL}</NavigationLink>, by phone at{' '}
      <NavigationLink path={`tel:${TEL}`}>{TEL}</NavigationLink>, or send her a message on Slack.
    </>
  ),
};
