import { FunctionComponent } from 'react';
import { ReactComponent as FbIcon } from '../../../asset/images/icons/fb.svg';
import { ReactComponent as TwIcon } from '../../../asset/images/icons/tw.svg';
import { ReactComponent as InIcon } from '../../../asset/images/icons/in.svg';
import { ReactComponent as ExitIcon } from '../../../asset/images/icons/exit.svg';
import styles from './MobileMenu.module.scss';
import classNames from 'classnames';
import RoleSelector, { RoleSelectorColorType } from '../../inputs/RoleSelector/RoleSelector';
import { UserRoleEnum } from '../../../enums/user';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import NavigationLink from '../../links/NavigationLink/NavigationLink';
import RoutesEnum from '../../../enums/routes';
import MainButton from '../../buttons/MainButton/MainButton';
import BalanceAndAccStatus from '../../blocks/BalanceAndAccStatus/BalanceAndAccStatus';
import { getCurrentUserRole } from '../../../utils/role';
import { LinkParams } from '../../../types/general';

type MobileMenuUiElements =
  | 'Avatar'
  | 'DashboardLink'
  | 'RocketFuel'
  | 'RoleSelector'
  | 'Navigation'
  | 'Footer'
  | 'BottomButtons';

interface MobileMenuProps {
  roleSelectorColor?: RoleSelectorColorType;
  userRole?: UserRoleEnum;
  hideRoleSelector?: boolean;
  elementsToHide?: MobileMenuUiElements[];
  customNavLinks?: LinkParams[];
  onExit(): void;
}

const MobileMenu: FunctionComponent<MobileMenuProps> = ({
  roleSelectorColor,
  userRole,
  hideRoleSelector,
  elementsToHide = [],
  customNavLinks = [],
  onExit,
}) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const userFirstName = useAppSelector((state) => state.user.user?.firstName);
  const userLastName = useAppSelector((state) => state.user.user?.lastName);
  const navigate = useNavigate();

  let localUserRole = userRole || getCurrentUserRole();

  return (
    <div className={styles['container']}>
      <div className={classNames(styles['background'], 'background')}></div>

      <div className={styles['scroll-wrapper']}>
        <div className={classNames('wrapper', 'type-1')}>
          <div className={styles['vertical-container']}>
            <header className={styles['header']}>
              <div
                className={classNames(
                  styles['header__block-list'],
                  isUserLoggedIn && styles['header__block-list--logged']
                )}
              >
                {isUserLoggedIn === false ? (
                  <>
                    {!elementsToHide.includes('RoleSelector') ||
                      (hideRoleSelector === true && (
                        <>
                          <div className={styles['header__block-item']}>
                            <p className={classNames(styles['header__text'], 'text', 'text--h5')}>
                              How do you identify?
                            </p>
                          </div>

                          <div className={styles['header__block-item']}>
                            <RoleSelector user_role={localUserRole} color={roleSelectorColor} />
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    <div className={styles['header__block-item']}>
                      <div className={styles['header__user']}>
                        <div className={styles['header__user__avatar']}>
                          <img src={require('../../../asset/images/avatar.png')} alt="Avatar" />
                        </div>

                        <div className={styles['header__user__content']}>
                          <p
                            className={classNames(
                              styles['header__user__content__name'],
                              'text',
                              'text--body-1',
                              'text--bold'
                            )}
                          >
                            {userFirstName} {userLastName}
                          </p>

                          {!elementsToHide.includes('DashboardLink') && (
                            <div className={styles['header__user__content__dashboard-link']}>
                              <NavigationLink
                                path={`/${RoutesEnum.ACCOUNT}`}
                                withBorder={true}
                                sizeType="smaller"
                                colorType="blue"
                              >
                                Go to Dashboard
                              </NavigationLink>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {!elementsToHide.includes('RocketFuel') && (
                      <div className={styles['header__block-item']}>
                        <BalanceAndAccStatus />
                      </div>
                    )}

                    {!elementsToHide.includes('RoleSelector') ||
                      (hideRoleSelector === true && (
                        <>
                          <div className={styles['header__block-item']}>
                            <p className={classNames(styles['header__text'], 'text', 'text--h5')}>
                              How do you identify?
                            </p>
                          </div>

                          <div className={styles['header__block-item']}>
                            <RoleSelector user_role={localUserRole} color={roleSelectorColor} />
                          </div>
                        </>
                      ))}
                  </>
                )}
              </div>
            </header>

            <main className={styles['main']}>
              <nav className={styles['menu-nav']}>
                <ul className={styles['menu-nav__list']}>
                  {customNavLinks.length > 0 ? (
                    customNavLinks.map((item, i) => (
                      <li>
                        <NavLink to={item.url} key={i}>
                          {item.text}
                        </NavLink>
                      </li>
                    ))
                  ) : (
                    <>
                      <li>
                        <NavLink to={`/${userRole}/${RoutesEnum.CONNECT}`}>Connect</NavLink>
                      </li>

                      <li>
                        <NavLink to={`/${userRole}/${RoutesEnum.BUILD}`}>Build</NavLink>
                      </li>

                      <li>
                        <NavLink to={`/${userRole}/${RoutesEnum.LEARN}`}>Learn</NavLink>
                      </li>

                      <li>
                        <NavLink to={`/${userRole}/${RoutesEnum.FUND}`}>Fund</NavLink>
                      </li>

                      <li>
                        <NavLink to={`/${userRole}/${RoutesEnum.GROW}`}>Grow</NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </main>

            <footer className={styles['footer']}>
              <div className={classNames(styles['socials-group'])}>
                <div className={classNames(styles['socials-group__item'])}>
                  <NavLink to="https://www.facebook.com/AstronomicATX/" target="_blank" rel="nofollow">
                    <FbIcon />
                  </NavLink>
                </div>

                <div className={classNames(styles['socials-group__item'])}>
                  <NavLink to="https://twitter.com/astronomic" target="_blank" rel="nofollow">
                    <TwIcon />
                  </NavLink>
                </div>

                <div className={classNames(styles['socials-group__item'])}>
                  <NavLink to="https://www.linkedin.com/company/astronomic/mycompany/" target="_blank" rel="nofollow">
                    <InIcon />
                  </NavLink>
                </div>
              </div>

              <div className={styles['footer__content']}>
                <div className={styles['footer__content__title']}>
                  <h5 className="text text--h5">Become an Astronomer</h5>
                </div>

                <div className={styles['footer__content__text']}>
                  {isUserLoggedIn === false ? (
                    <div className="text text--body-2">
                      Our mission is to enable as much entrepreneurship as possible.{' '}
                      <NavigationLink path={`/${RoutesEnum.SIGN_UP}`} sizeType="small">
                        Create your free account
                      </NavigationLink>{' '}
                      to accelerate your company’s ascent to the moon.
                    </div>
                  ) : (
                    <div className="text text--body-2">
                      Our mission is to enable as much entrepreneurship as possible. Now it is time accelerate your
                      company’s ascent to the moon.
                    </div>
                  )}
                </div>
              </div>
            </footer>

            <div className={styles['bottom']}>
              <div className={styles['bottom__button-group']}>
                {isUserLoggedIn === false ? (
                  <>
                    <div className={styles['bottom__button-group__item']}>
                      <MainButton
                        sizeType={'large'}
                        visualType="main"
                        onClick={() => {
                          navigate(`/${RoutesEnum.SIGN_UP}`);
                        }}
                      >
                        Join for free
                      </MainButton>
                    </div>

                    <div className={styles['bottom__button-group__item']}>
                      <MainButton
                        sizeType={'large'}
                        visualType="blank"
                        onClick={() => {
                          navigate(`/${RoutesEnum.SIGN_IN}`);
                        }}
                      >
                        Sign in
                      </MainButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={classNames(
                        styles['bottom__button-group__item'],
                        styles[`bottom__button-group__item--is-single-${isUserLoggedIn}`]
                      )}
                    >
                      <MainButton
                        sizeType={'large'}
                        visualType="blank"
                        iconLeft={<ExitIcon style={{ width: '20px', fill: 'var(--color-white)' }} />}
                        onClick={() => {
                          onExit();
                        }}
                      >
                        Logout
                      </MainButton>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
